import React, { ComponentProps } from "react"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import ReactGA from "react-ga"

interface ButtonProps {
  large?: boolean
  small?: boolean
  alt?: boolean
  icon?: IconDefinition
  iconPos?: "left" | "right"
  as?: "link" | "a" | "button"
  to?: string
  eventLabel?: string
}

const Button: React.FC<ButtonProps & ComponentProps<"button">> = ({
  children,
  as,
  large,
  small,
  alt,
  icon,
  iconPos,
  to,
  eventLabel,
}) => {
  if (!!icon && !iconPos) {
    throw new Error("Cannot provide icon with iconpos")
  }

  const props = {
    className: cx("button", {
      large: large,
      small: small,
      alt: alt,
      icon: !!icon,
      left: iconPos === "left",
      right: iconPos === "right",
    }),
    ...(as === "a" && {
      href: to,
      rel: "noopenener, noreferrer",
      target: "_blank",
    }),
    ...(as === "link" && { to }),
  }

  const contents = (
    <>
      {!small && !!icon && iconPos === "left" && (
        <FontAwesomeIcon icon={icon} />
      )}
      {children}
      {!small && !!icon && iconPos === "right" && (
        <FontAwesomeIcon icon={icon} />
      )}
    </>
  )

  switch (as) {
    case "a":
      return (
        <ReactGA.OutboundLink
          eventLabel={eventLabel ?? "genericButton"}
          to={props.href}
          {...props}
        >
          {contents}
        </ReactGA.OutboundLink>
      )

    case "button":
      return <button {...props}>{contents}</button>

    default:
      return <Link {...props}>{contents}</Link>
  }
}

Button.defaultProps = {
  as: "link",
}

export default Button
