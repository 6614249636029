// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-pages-events-events-tsx": () => import("./../../../src/content/pages/events/events.tsx" /* webpackChunkName: "component---src-content-pages-events-events-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-judging-calculator-tsx": () => import("./../../../src/pages/judging-calculator.tsx" /* webpackChunkName: "component---src-pages-judging-calculator-tsx" */),
  "component---src-pages-sponsor-interest-tsx": () => import("./../../../src/pages/sponsor-interest.tsx" /* webpackChunkName: "component---src-pages-sponsor-interest-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */)
}

